<template>
  <CCol sm="12">
    <b-card>
      <b-card-header
        ><h4>{{ title }}</h4></b-card-header
      >
      <b-card-body>
        <l-map
          v-if="this.geojson"
          ref="myMap"
          :center="[46.980388, 8.388605]"
          :zoom="8"
          style="height: 600px;"
        >
          <l-tile-layer
            url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          />
          <!-- if evolution HESTA -->
          <l-choropleth-layer
            v-if="type == 'evolution'"
            :data="data"
            titleKey="canton"
            idKey="canton_id"
            :value="value"
            :extraValues="otherValues"
            geojsonIdKey="canton_id"
            :geojson="geojson"
            :colorScale="colorScale"
          >
            <template #props>
              <l-info-control
                :item="props.currentItem"
                :unit="props.unit"
                :title="$t('maps.canton')"
                :placeholder="$t('maps.hover_canton')"
              />
              <l-reference-chart
                :title="$t('maps.evolution_overnights_canton')"
                :colorScale="colorScale"
                :min="props.min"
                :max="props.max"
                position="topright"
              />
            </template>
          </l-choropleth-layer>

          <!-- if airdna data -->
          <l-choropleth-layer
            v-if="type == 'airdna'"
            :data="data"
            titleKey="canton"
            idKey="canton_id"
            :value="valueAirdna"
            :extraValues="otherValuesAirdna"
            geojsonIdKey="canton_id"
            :geojson="geojson"
            :colorScale="colorScale"
          >
            <template #props>
              <l-info-control
                :item="props.currentItem"
                :unit="props.unit"
                :title="$t('maps.canton')"
                :placeholder="$t('maps.hover_canton')"
              />
              <l-reference-chart
                :title="$t('platforms.listings_canton')"
                :colorScale="colorScale"
                :min="props.min"
                :max="props.max"
                position="topright"
              />
            </template>
          </l-choropleth-layer>
        </l-map>
      </b-card-body>
    </b-card>
  </CCol>
</template>

<script>
import { InfoControl, ReferenceChart, ChoroplethLayer } from "vue-choropleth";

export default {
  components: {
    "l-info-control": InfoControl,
    "l-reference-chart": ReferenceChart,
    "l-choropleth-layer": ChoroplethLayer
  },
  props: {
    title: { type: String, required: true },
    mapData: { type: Array, required: true },
    type: { type: String, required: true }
  },
  data() {
    return {
      geojson: null,
      popupName: null,
      data: null,
      colorScale: ["#fee5d9", "#fcae91", "#fb6a4a", "#cb181d"],
      value: {
        key: "evo",
        metric: `%`
      },
      valueAirdna: {
        key: "listings",
        metric: this.$t("platforms.listings")
      },
      otherValues: [
        {
          key: "overnights",
          metric: this.$t("recap.overnights")
        },
        {
          key: "arrivals",
          metric: this.$t("provenances.arrivals")
        }
      ],
      otherValuesAirdna: [
        {
          key: "adr",
          metric: this.$t("platforms.adr")
        },
        {
          key: "reservationDays",
          metric: this.$t("platforms.reservationDays_median")
        },
        {
          key: "occupation",
          metric: this.$t("platforms.occupation_median")
        }
      ],
      mapOptions: {
        title: "null",
        style: function style() {
          return {
            weight: 4,
            opacity: 0.7,
            color: "#666",
            fillOpacity: 0.3
          };
        }
      },
      onEachFeature: function onEachFeature(feature, layer) {
        var self = this;
        layer.on("mouseover", function() {
          self.popupName = feature.properties.name;
          //open popup
          /* var popup = L.popup()
                        .setLatLng(e.latlng) 
                        .setContent('<h1 style="color: grey; margin-bottom: 0;">'+ feature.properties.Name +'</h1>')
                        .openOn(self.$refs.myMap.mapObject);  */
        });
      }
    };
  },
  async created() {
    this.mapOptions.title = this.title;
    this.data = this.mapData;
    const response = await fetch("/maps/cantons_ch.json");
    const data = await response.json();
    this.geojson = data;
  }
};
</script>

<style lang="scss">
.vue2leaflet-map.leaflet-container.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag {
  height: 600px;
}</style
>>
